import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  eventName,
  userId,
  token,
  url,
  purchasedPhysical,
  purchasedPrecongressWorkshop,
  purchasedPrecongressUltrasound,
  physical,
  precongressWorkshop,
  precongressUltrasound,
  sst,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedPrecongressWorkshop, setSelectedPrecongressWorkshop] =
    useState(null);
  const [selectedPrecongressUltrasound, setSelectedPrecongressUltrasound] =
    useState(null);

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[
            selected,
            selectedPrecongressWorkshop,
            selectedPrecongressUltrasound,
          ]}
        />

        <ProductCard
          cardTitle={eventName}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
              setSelectedPrecongressWorkshop(null);
              setSelectedPrecongressUltrasound(null);
            }
          }}
          handleRadio={() => {}}
        />

        {precongressWorkshop.length > 0 && (purchasedPhysical || selected) && (
          <ProductCard
            cardTitle="Pre-congress Workshop"
            products={precongressWorkshop}
            selectedState={selectedPrecongressWorkshop}
            setSelectedState={setSelectedPrecongressWorkshop}
            activated={!purchasedPrecongressWorkshop}
            handleCheckbox={(e) => {
              if (!e.target.checked) {
                setSelectedPrecongressWorkshop(null);
              }
            }}
            handleRadio={() => {}}
          />
        )}

        {precongressUltrasound.length > 0 &&
          (purchasedPhysical || selected) && (
            <ProductCard
              cardTitle="Pre-congress Ultrasound"
              products={precongressUltrasound}
              selectedState={selectedPrecongressUltrasound}
              setSelectedState={setSelectedPrecongressUltrasound}
              activated={!purchasedPrecongressUltrasound}
              handleCheckbox={(e) => {
                if (!e.target.checked) {
                  setSelectedPrecongressUltrasound(null);
                }
              }}
              handleRadio={() => {}}
            />
          )}

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 8% SST
          </strong>
        )}
        {/* for international delegate is compulsory to select one of the precongress */}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={
            purchasedPhysical
              ? purchasedPhysical &&
                selectedPrecongressWorkshop === null &&
                selectedPrecongressUltrasound === null
              : (selected === null &&
                  selectedPrecongressWorkshop === null &&
                  selectedPrecongressUltrasound === null) ||
                selected === null
          }
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
